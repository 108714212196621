import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';
import { XpoBoardModule, XpoBoardViewsModule, XpoFiltersModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoDialogModule,
  XpoIconModule,
  XpoSnackBarModule,
} from '@xpo-ltl/ngx-ltl-core';
import { SharedModule } from './shared/shared.module';

const modules = [
  SharedModule,
  FlexLayoutModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatDatepickerModule,
  // Xpo Components
  XpoButtonModule,
  XpoCardModule,
  XpoDialogModule,
  XpoIconModule,
  XpoSnackBarModule,
  // Xpo board Components
  XpoAgGridBoardModule,
  XpoBoardModule,
  XpoBoardViewsModule,
  XpoFiltersModule,
];

const pipes = [];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...modules],
  providers: [...pipes],
  exports: [...modules, ...pipes],
})
export class UiComponentsModule {}
