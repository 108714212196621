import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';

const components = [ActionBarComponent, ActionBarLeftContent, ActionBarCenterContent, ActionBarRightContent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    XpoIconModule,
    // Pipes/Directives
  ],
  exports: [...components],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {}
