import { Injectable } from '@angular/core';
import { Code, User } from '@xpo-ltl/sdk-common';
import { PricingAdminPersonnel } from '@xpo-ltl/sdk-pricing';
import _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, map, retryWhen, switchMap, take } from 'rxjs/operators';
import { PricingCodesCategorySubCategory } from '../../enums/pricing-codes-category-sub-category.enum';
import { LoggedInUserService } from '../logged-in-user/logged-in-user.service';
import { PricingService } from '../pricing/pricing.service';

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  userAccess = new BehaviorSubject<Array<string>>(undefined);
  userAccess$ = this.userAccess.asObservable();

  constructor(private pricingService: PricingService, private loggedInUser: LoggedInUserService) {
    this.loggedInUser.user$.subscribe((user: User) => {
      if (user) {
        this.getUserAccessApps(user)
          .pipe(
            retryWhen((errors) => {
              return errors.pipe(delay(1000), take(5));
            })
          )
          .subscribe((permissions) => console.log('[Permissions]', permissions));
      }
    });
  }

  getUserAccessApps(user: User): Observable<Array<string>> {
    if (user) {
      return this.pricingService.getEmployeeDetails(user.employeeId).pipe(
        switchMap((personnel: PricingAdminPersonnel) => {
          const employeeRoleType = personnel.employeeRoleType;
          const additionalRoleType = personnel.additionalRoleType;
          const roles = [];
          if (employeeRoleType && employeeRoleType.trim()) {
            roles.push(employeeRoleType.trim());
          }
          if (additionalRoleType && additionalRoleType.trim()) {
            roles.push(...additionalRoleType.split(',').map((role) => role.trim()));
          }
          return this.pricingService.getPricingCodes().pipe(
            switchMap((response: Code[]) => {
              const userAccess = _.filter(response, (pricingCode) => {
                return (
                  pricingCode.category === PricingCodesCategorySubCategory.PricingApp &&
                  pricingCode.subCategory === PricingCodesCategorySubCategory.Security &&
                  pricingCode.value.split(',').some((r) => roles.indexOf(r) >= 0)
                );
              }).map((permission: Code) => permission.name);
              this.userAccess.next(userAccess);
              return of(userAccess);
            })
          );
        }),
        catchError((err: any) => {
          console.error(err);
          return of([]);
        })
      );
    }
  }

  hasUserAccess(appName: string): Observable<boolean> {
    if (this.userAccess.value) {
      return of(this.userAccess.value.includes(appName));
    } else {
      return this.getUserAccessApps(this.loggedInUser.User).pipe(
        map((data) => {
          return data.includes(appName);
        })
      );
    }
  }
}
