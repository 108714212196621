import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PricingAdminPersonnel } from '@xpo-ltl/sdk-pricing';
import _ from 'lodash';
import { AppRoutes } from '../shared/enums/app-routes.enum';
import { HostNames } from '../shared/enums/host-names.enum';
import { AppNames } from '../shared/enums/landing-page/app-names.enum';
import { UserAccessService } from '../shared/services/user-access/user-access.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  readonly pricingApps: Array<any> = [
    {
      label: AppNames.dynamic_shipments,
      click: true,
      /*did not add the icon as this option will not be visible for the user's
      in prod for 8/30 release and we didn't finalised what icon need to be used for this option ,
      will add once we need this option in prod */
    },
    {
      label: AppNames.spot_quote,
      click: true,
      iconName: 'lens',
    },
  ];
  landingPageApps;

  constructor(private router: Router, private userAccess: UserAccessService) {}

  ngOnInit() {
    this.userAccess.userAccess$.subscribe((resp) => {
      if (resp) {
        this.landingPageApps = this.getLandingPageAppsBasedOnAccess(resp);
        if (this.landingPageApps.length === 0) {
          this.router.navigate([AppRoutes.NOT_AUTHORIZED_PAGE]);
        }
      }
    });
  }

  getLandingPageAppsBasedOnAccess(allowedApps) {
    return _.filter(this.pricingApps, (app) => {
      return allowedApps.includes(app.label);
    });
  }

  getCurrentHostName() {
    const window_hostname = window.location.hostname;
    switch (window_hostname) {
      case HostNames.localhost:
        return `${HostNames.dyn}${HostNames.cloud_j}`;
      default:
        return `${HostNames.dyn}${window_hostname}`;
    }
  }

  navigateToApps(appName) {
    console.log(appName);
    const hostName = this.getCurrentHostName();
    switch (appName) {
      case AppNames.dynamic_shipments:
        window.open(`https://${hostName}/dynamic-shipments/list-page`, '_blank');
        break;
      case AppNames.spot_quote:
        window.open(`https://${hostName}/spot-quote/list-page`, '_blank');
        break;
    }
  }

  ngOnDestroy() {}
}
