import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { NotAuthorizedComponent } from './dialogs/not-authorized/not-authorized.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AppRoutes } from './shared/enums/app-routes.enum';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.LANDING_PAGE,
    component: LandingPageComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.NOT_AUTHORIZED_PAGE,
    component: NotAuthorizedComponent,
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.PRICING_MODULE_GROUPS,
    loadChildren: () =>
      import('./pricing-module-groups/pricing-module-groups.module').then((m) => m.PricingModuleGroupsModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.PRICING_MODULE_LIBRARY,
    loadChildren: () =>
      import('./pricing-module-library/pricing-module-library.module').then((m) => m.PricingModuleLibraryModule),
    canLoad: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.PRICING_MODULE_GROUP_MANAGEMENT,
    loadChildren: () =>
      import('./pricing-module-group-management/pricing-module-group-management.module').then(
        (m) => m.PricingModuleGroupManagementModule
      ),
    canLoad: [XpoAuthenticationGuard],
  },
  // ADD ANY NEW ROUTE / PAGE HERE
  {
    path: '**',
    redirectTo: `/${AppRoutes.LANDING_PAGE}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
