import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { LoginService } from '@xpo-ltl/login';
import { XpoMessagingPopoverMessage } from '@xpo-ltl/module-executor-ui/lib/modules/module-notifications/components/notifications/notifications.component';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoAccountPopoverConfig, XpoAppSwitcherApplication, XpoFeedback, XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { User } from '@xpo-ltl/sdk-common';
import { XpoLtlAppSwitcherService, XpoLtlFeedbackService, XpoLtlReleaseNotesService } from '@xpo/ngx-ltl';
import { invoke as _invoke, isEmpty as _isEmpty } from 'lodash';
import { interval, Observable, of } from 'rxjs';
import { delay, filter, retryWhen, skipWhile, take, tap } from 'rxjs/operators';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppName } from './shared/enums/app-name.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { UserAccess } from './shared/enums/user-access.enum';
import { LoggedInUserService } from './shared/services/logged-in-user/logged-in-user.service';
import { UserAccessService } from './shared/services/user-access/user-access.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  constructor(
    private configManagerService: ConfigManagerService,
    public feedbackService: XpoLtlFeedbackService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    private loginService: LoginService,
    private dialog: MatDialog,
    private appSwitcherService: XpoLtlAppSwitcherService,
    private loggedInUser: LoggedInUserService,
    private authService: XpoLtlAuthenticationService,
    private userAccess: UserAccessService,
    private router: Router
  ) {
    const routes = [
      {
        label: 'Landing Page',
        path: `/${AppRoutes.LANDING_PAGE}`,
      },
      {
        label: 'Monitor Groups',
        path: `/${AppRoutes.PRICING_MODULE_GROUPS}`,
      },
      {
        label: 'Monitor Library',
        path: `/${AppRoutes.PRICING_MODULE_LIBRARY}`,
      },
    ];

    /** Shell setup */
    this.title = AppName.Pricing;
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
      console.log(info);
      // Extra config or API calls here if needed
    });
    /** Initialize SIC switcher by getting logged in user's sic */
    this.handleLoggedInUser();
    this.loginService.userLoggedIn$.subscribe(() => {
      this.handleLoggedInUser();
    });
    this.userAccess.userAccess$.pipe(filter((permissions) => permissions !== undefined)).subscribe((permissions) => {
      this.routesBasedOnPermission = this.getUserRoutes(routes, permissions);
    });
    this.messageData = of([]);
  }

  title: string;
  build: string;
  apps$: Observable<XpoAppSwitcherApplication[]>;
  routesBasedOnPermission: XpoShellRoute[];
  defaultSic = 'UPO';
  accountPopoverConfig: XpoAccountPopoverConfig;
  messageData: Observable<XpoMessagingPopoverMessage[]>;

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  private getUserRoutes(routes: XpoShellRoute[], permissions: Array<string>): XpoShellRoute[] {
    if (permissions.includes(UserAccess.BATCH)) {
      return routes;
    } else {
      return [
        {
          label: 'Landing Page',
          path: `/${AppRoutes.LANDING_PAGE}`,
        },
      ];
    }
  }

  handleSicChanged(newSic: string): void {
    /** TODO: how does your app respond to SIC changes? Do you need to refresh something... */
    console.log('New SIC', newSic);
  }

  handleFeedbackClick(): void {
    this.feedbackService.feedbackConfig$.pipe(take(1)).subscribe((config) => {
      this.dialog.open(XpoFeedback, { data: config });
    });
  }

  handleReleaseNotesClick(): void {
    this.releaseNotesService.showReleaseNotes().subscribe(() => {});
  }

  private handleLoggedInUser(): void {
    this.loginService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(5))))
      .subscribe(
        (user: User) => {
          if (user) {
            this.loggedInUser.user.next(user);
            this.populateAccountPopover(user);
            this.populateAppSwitcher();
            this.setDynatraceUserIdentity(user);
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  private populateAccountPopover(user: User): void {
    const fullName = `${user.givenName} ${user.lastName}`;

    this.accountPopoverConfig = {
      imageUri: AppComponent.getProfilePictureUrl(user.emailAddress),
      name: fullName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [
        { title: 'My Account', url: AccountUrls.myAccount },
        { title: 'Help', url: AccountUrls.help },
        { title: 'Privacy Policy', url: AccountUrls.privacyPolicy },
      ],
    };
  }

  private signOut(): void {
    // Removing the local storage keys
    this.loginService.clear();
  }

  private populateAppSwitcher(): void {
    this.apps$ = this.appSwitcherService.getAppList();
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(
        window['dtrum'],
        'identifyUser',
        !_isEmpty(user.emailAddress) ? user.emailAddress : !_isEmpty(user.userId) ? user.userId : 'PND_USER'
      );

    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }
}
