import { Injectable } from '@angular/core';
import { User } from '@xpo-ltl/sdk-common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoggedInUserService {
  user = new BehaviorSubject<User>(undefined);
  user$ = this.user.asObservable();

  get User(): User {
    return this.user.value;
  }

  set User(value: User) {
    this.user.next(value);
  }

  constructor() {}
}
