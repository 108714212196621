import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { XpoButtonModule, XpoCardModule } from '@xpo-ltl/ngx-ltl-core';
import { SharedModule } from '../shared/shared.module';
import { LandingPageComponent } from './landing-page.component';

@NgModule({
  declarations: [LandingPageComponent],
  imports: [CommonModule, SharedModule, RouterModule, XpoCardModule, XpoButtonModule, FlexLayoutModule],
})
export class LandingPageModule {}
