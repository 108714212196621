import { Injectable } from '@angular/core';
import { Code } from '@xpo-ltl/sdk-common';
import {
  GetPricingAnalystDetailsResp,
  GetPricingAnalystDetailsRqst,
  ListPricingCodesByTypeQuery,
  ListPricingCodesByTypeResp,
  PricingAdminPersonnel,
  PricingApiService,
} from '@xpo-ltl/sdk-pricing';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PricingCodesCategorySubCategory } from '../../enums/pricing-codes-category-sub-category.enum';
import { ConstantsService } from '../constants/constants.service';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  private pricingCodesCache$: Observable<Code[]>;

  constructor(private pricingApi: PricingApiService, private constants: ConstantsService) {}

  getEmployeeDetails(empId: string): Observable<PricingAdminPersonnel> {
    const requestParams = new GetPricingAnalystDetailsRqst();
    requestParams.pricingAnalystEmplId = empId;
    return this.pricingApi.getPricingAnalystDetails(requestParams).pipe(
      map((resp: GetPricingAnalystDetailsResp) => {
        return resp.pricingAnalystDetails;
      })
    );
  }

  requestPricingCodes(): Observable<Code[]> {
    const queryParams = new ListPricingCodesByTypeQuery();
    queryParams.categoryCode = [PricingCodesCategorySubCategory.PricingApp];
    queryParams.subCategoryCode = [PricingCodesCategorySubCategory.Security];
    return this.pricingApi.listPricingCodesByType(queryParams, { loadingOverlayEnabled: false }).pipe(
      map((response: ListPricingCodesByTypeResp) => {
        return response.codeDetails;
      })
    );
  }

  getPricingCodes() {
    if (!this.pricingCodesCache$) {
      this.pricingCodesCache$ = this.requestPricingCodes().pipe(shareReplay());
    }
    return this.pricingCodesCache$;
  }
}
